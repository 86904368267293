import React from "react";
import HomePage from "../HomePage";
import Features from "../Features";
import Subscriptions from "../Subscriptions";
import Satisfaction from "../Satisfaction";

export default function RootPage() {
    return (
        <>
            <HomePage />
            <Features />
            <Subscriptions />
            <Satisfaction />
        </>
    );
}
