import React from "react";
import styles from "./Satisfaction.module.css";
import { avatars } from "./assets/images";
import { useTranslation } from "react-i18next";

function CommentItem({ text, name, image }) {
    return (
        <div className={styles.commentItem}>
            <div className={styles.commentText}>{text}</div>
            <div className={styles.commentExtendedBackground} />
            <img className={styles.avatar} src={image} alt={"avatar"} />
            <div className={styles.name}>{name}</div>
        </div>
    );
}

export default function Comments() {
    const { t } = useTranslation();
    return (
        <div className={["centeredRow", styles.comments].join(" ")}>
            {(t("satisfaction.comments", { returnObjects: true }) ?? []).map(
                (it) => (
                    <CommentItem
                        key={it.name}
                        {...it}
                        image={avatars[it.image]}
                    />
                )
            )}
        </div>
    );
}
