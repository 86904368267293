import React, { useRef } from "react";
import styles from "./Subscriptions.module.css";
import { useTranslation } from "react-i18next";

function SubscriptionItem({ className, deal, price, frequency }) {
    return (
        <div className={[styles.subItem, className].join(" ")}>
            <div className={[styles.subPrice, "centeredRow"].join(" ")}>
                {price} <span>({frequency})</span>
            </div>
            <div className={[styles.subDeal, "centeredRow"].join(" ")}>
                <p className={"centeredRow"}>{deal}</p>
                <div className={styles.arrow}></div>
            </div>
        </div>
    );
}

export default function SubscriptionsCard() {
    const ref = useRef();
    const { t } = useTranslation();
    const deals = t("subscription.pricing_plans.deals", {
        returnObjects: true,
    });
    return (
        <div ref={ref} className={[styles.card].join(" ")}>
            <h2>{t("subscription.pricing_plans.header")}</h2>
            <div className={styles.dealsList}>
                {[
                    {
                        className: styles.greenDeal,
                        ...deals["yearly"],
                    },
                    {
                        className: styles.blueDeal,
                        ...deals["lifetime"],
                    },
                    {
                        className: styles.yellowDeal,
                        ...deals["regular"],
                    },
                ].map((card) => (
                    <SubscriptionItem key={card.deal} {...card} />
                ))}
            </div>
        </div>
    );
}
