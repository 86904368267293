import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./NavBar.module.css";

export default function LanguageSwitcher(
    { className, extended, style } = { extended: false }
) {
    const { i18n } = useTranslation();

    const names = extended
        ? {
              ar: "العربية",
              en: "English",
          }
        : {
              ar: "ع",
              en: "EN",
          };
    return (
        <div
            style={style}
            className={[styles.languageSwitcherContainer, className].join(" ")}
        >
            <button
                className={styles.languageSwitcher}
                style={{
                    fontFamily: i18n.language === "en-US" ? "Cairo" : "Inter",
                    color: extended ? "white" : "black",
                }}
                onClick={() => {
                    console.log(i18n.language);
                    const newLanguage =
                        i18n.language === "en-US" ? "ar" : "en-US";
                    i18n.changeLanguage(newLanguage);
                    localStorage.setItem("malak.language", newLanguage);
                }}
            >
                {i18n.language === "en-US" ? names.ar : names.en}
            </button>
        </div>
    );
}
