import image1 from "./1.svg";
import image2 from "./2.svg";
import image3 from "./3.svg";
import image4 from "./4.svg";
import image5 from "./5.svg";
import image6 from "./6.svg";

export const featuresImages = {
    1: image1,
    2: image2,
    3: image3,
    4: image4,
    5: image5,
    6: image6,
}