import React, { useEffect, useState } from "react";
import styles from "./NavBar.module.css";
import { NavLink } from "react-router-dom";

export function NavItem({ name, path, selected, onNavigation }) {
    return (
        <li ref={onNavigation}>
            <NavLink className={selected ? styles.selected : ""} to={path}>
                {name}
            </NavLink>
        </li>
    );
}

export default function NavList({ routes, current }) {
    const [containerStart, setContainerStart] = useState(undefined);
    const [indicatorPosition, setIndicatorPosition] = useState();

    useEffect(() => {
        window.addEventListener("resize", () => {
            const width = window.innerWidth;

            if (width === 950 || width === 600) setContainerStart(undefined);
        });
    }, []);

    function onNavigation(element, item, i) {
        if (!element) return;

        if (i === 0) setContainerStart(+element.getBoundingClientRect().left);

        if (item.path === current) {
            const relative =
                +element.getBoundingClientRect().left - containerStart;
            const position =
                relative + +element.getBoundingClientRect().width / 2;
            setTimeout(() => setIndicatorPosition(position), 100);
        }
    }

    return (
        <div className={styles.routes}>
            <ul className={styles.list}>
                {routes.map((item, i) => (
                    <NavItem
                        key={item.path}
                        selected={item.path === current}
                        onNavigation={(element) =>
                            onNavigation(element, item, i)
                        }
                        {...item}
                    />
                ))}
            </ul>
            {indicatorPosition ? (
                <div
                    className={[styles.indicator].join(" ")}
                    style={{ left: `${indicatorPosition}px` }}
                />
            ) : null}
        </div>
    );
}
