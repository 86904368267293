import navBarLocalization from "../../features/NavBar/assets/navbar.localization.json";
import homeLocalization from "../../features/HomePage/assets/home.localization.json";
import featuresLocalization from "../../features/Features/assets/features.localization.json";
import subscriptionLocalization from "../../features/Subscriptions/assets/subscriptions.localization.json";
import satisfactionLocalization from "../../features/Satisfaction/assets/satisfaction.localizaion.json";
import footerLocalization from "../../features/Footer/assets/footer.localization.json";
import policyLocalization from "../../features/PrivacyPolicy/assets/policy.localization.json";

const resources = {
    en: {
        translation: {
            nav: navBarLocalization.en,
            home: homeLocalization.en,
            features: featuresLocalization.en,
            subscription: subscriptionLocalization.en,
            satisfaction: satisfactionLocalization.en,
            footer: footerLocalization.en,
            policy: policyLocalization.en,
            common: {
                malak: "Malak App",
                language: "Language",
                apps: {
                    android: "Android App",
                    ios: "iOS App",
                },
            },
        },
    },
    ar: {
        translation: {
            nav: navBarLocalization.ar,
            home: homeLocalization.ar,
            features: featuresLocalization.ar,
            subscription: subscriptionLocalization.ar,
            satisfaction: satisfactionLocalization.ar,
            footer: footerLocalization.ar,
            policy: policyLocalization.ar,
            common: {
                malak: "مالك",
                apps: {
                    android: "تطبيق الاندرويد",
                    ios: "تطبيق الـiOS",
                },
                language: "اللغة",
            },
        },
    },
};

export default resources;
