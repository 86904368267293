import React from "react";
import styles from "./Features.module.css";
import PageSection from "../PageSection";
import FeatureCard from "./FeatureCard";
import { featuresImages } from "./assets/images";
import { useTranslation } from "react-i18next";

export default function Features(props) {
    const { t } = useTranslation();
    const featuresTitles = t("features.features_list", { returnObjects: true });

    return (
        <PageSection id={"Features"} className={styles.container} {...props}>
            <h1>{t("features.header")}</h1>
            <div className={styles.cardGrid}>
                {Array.from({ length: 6 }, (_, i) => i + 1).map((i) => {
                    return (
                        <FeatureCard
                            key={i}
                            text={featuresTitles[i]}
                            image={featuresImages[i]}
                        />
                    );
                })}
            </div>
        </PageSection>
    );
}
