import React from "react";
import styles from "./HomePage.module.css";
import PageSection from "../PageSection";
import { ReactComponent as PhonesIcon } from "./assets/images/phone.svg";
import StoresBar from "../StoresBar";
import { useTranslation } from "react-i18next";

export default function HomePage({ anchorPosition, ...props }) {
    const { t } = useTranslation();
    const main = t("home.main", { returnObjects: true });
    return (
        <PageSection id={"Home"} anchorPosition={anchorPosition} {...props}>
            <div className={styles.container}>
                <PhonesIcon className={styles.phones} />
                <div className={styles.content}>
                    <header className={styles.main}>
                        <span>{main[1]}</span>
                        {main[2]}
                        <br />
                        <span>{main[3]}</span>
                        {main[4]}
                    </header>
                    <p className={styles.secondary}>{t("home.secondary")}</p>
                    <StoresBar className={styles.stores} />
                </div>
            </div>
        </PageSection>
    );
}
