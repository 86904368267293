import React, { useEffect, useRef } from "react";
import styles from "./PageSection.module.css";
import useIntersecting from "../../hooks/useIntersecting";
import { useNavigate } from "react-router-dom";

export default function PageSection({
    className,
    id,
    anchorPosition,
    ...rest
}) {
    const ref = useRef();
    const isIntersecting = useIntersecting(ref);
    const navigate = useNavigate();

    useEffect(() => {
        if (!id || !isIntersecting) return;
        const startingHash = `#${sessionStorage.getItem("back")}`;
        const currentHash = window.location.hash;
        // console.log({ currentHash, startingHash });
        if (startingHash === currentHash) navigate(`/#${id}`);
        sessionStorage.setItem("back", id);
    }, [id, isIntersecting]);
    return (
        <section
            id={id}
            className={[styles.container, className].join(" ")}
            {...rest}
        >
            <div className={styles.anchor} ref={ref} />
            <div className={"content"}>{rest.children}</div>
        </section>
    );
}
