import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resources from "./resources";

const localization = i18n;

localization
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
            resources,
            fallbackLng: "en",
            interpolation: {
                escapeValue: false,
            },
            react: {
                useSuspense: false
            },
        }
    );

export default localization;
