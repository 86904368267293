import React from "react";
import styles from "./Burger.module.css";

export default function Burger({className, isOpen, ...rest}) {
    return (
        <div
            className={[styles.menu, isOpen ? styles.open : "", className].join(" ")}
            {...rest}
        >
            <div/>
        </div>
    )
}