import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function useAnchor() {

    const location = useLocation();
    const anchor = location.hash.substr(1);
    const hashScroll = () => {
        const element = document.getElementById(anchor);
        if (element) element.scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    useEffect(() => {
        if (anchor) hashScroll();
        else window.scrollTo(0,0);
    }, [anchor]);

    return location.pathname + location.hash;
}