import { useEffect, useState } from "react";

export default function useIntersecting(ref) {
    const [isIntersecting, setIntersecting] = useState(false);
    const ioConfiguration = {
        threshold: 0,
    };
    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        ioConfiguration
    );

    useEffect(() => {
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
}
