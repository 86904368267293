import React from "react";
import styles from "./PrivacyPolicy.module.css";
import PageSection from "../PageSection";
import { useTranslation } from "react-i18next";

export default function PrivacyPolicy({}) {
    const { t } = useTranslation();
    return (
        <PageSection className={styles.container}>
            <header>
                <h1>{t("policy.header.title")}</h1>
                <h2>{t("policy.header.subtitle")}</h2>
                <h3>{t("policy.header.desc")}</h3>
            </header>

            <ol>
                {(t("policy.list", { returnObjects: true }) ?? []).map(
                    (item) => (
                        <li key={item.title}>
                            <h1>{item.title}</h1>
                            {item.body.map((bodyItem, i) =>
                                Array.isArray(bodyItem) ? (
                                    <ul key={i}>
                                        {bodyItem.map((bodyListItem) => (
                                            <li key={bodyListItem}>
                                                {bodyListItem}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p key={i}>{bodyItem}</p>
                                )
                            )}
                        </li>
                    )
                )}
            </ol>
            <hr />
            <footer>
                <h2>{t("policy.footer.title")}</h2>
                <p>
                    {Object.keys(
                        t("policy.footer.subtitle", { returnObjects: true })
                    ).map((key) => {
                        const value = t("policy.footer.subtitle", {
                            returnObjects: true,
                        })[key];
                        return key !== "email" ? (
                            <span key={key}>{value}</span>
                        ) : (
                            <a key={key} href={`mailto:${value}`}>
                                {" "}
                                {value}{" "}
                            </a>
                        );
                    })}
                </p>
            </footer>
        </PageSection>
    );
}
