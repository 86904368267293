import {useEffect, useState} from "react";

export default function useScrollPosition() {
    const [position, setPosition] = useState(0)
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setPosition(window.pageYOffset ?? 0);
        })
    }, [])

    return position;
}