import React from "react";
import styles from "./Satisfaction.module.css";
import PageSection from "../PageSection";
import Comments from "./Comments";
import StoresBar from "../StoresBar";
import { useTranslation } from "react-i18next";

export default function Satisfaction({}) {
    const { t } = useTranslation();
    return (
        <>
            <PageSection className={styles.storesContainer}>
                <header>
                    <h1 className="centeredHeading">
                        {t("satisfaction.header")}
                    </h1>
                    <StoresBar className={styles.stores} />
                </header>
            </PageSection>
            <PageSection className={styles.container}>
                <header>
                    <h1 className="centeredHeading">
                        {t("satisfaction.header")}
                    </h1>
                    <h3>{t("satisfaction.sub_header")}</h3>
                </header>
                <Comments />
            </PageSection>
        </>
    );
}
