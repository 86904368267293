import React from "react";
import styles from "./Subscriptions.module.css";
import PageSection from "../PageSection";
import { ReactComponent as TickIcon } from "../../assets/images/tick.svg";
import SubscriptionsCard from "./SubscriptionsCard";
import { useTranslation } from "react-i18next";

export default function Subscriptions(props) {
    const { t } = useTranslation();
    return (
        <PageSection id={"Pricing"} className={styles.container} {...props}>
            <h1>
                {t("subscription.header.1")}{" "}
                <strong>{t("subscription.header.2")}</strong>{" "}
                {t("subscription.header.3")}
            </h1>
            <div className={styles.grid}>
                <div>
                    <h2>
                        {t("subscription.sub_header.1")}{" "}
                        <strong>{t("subscription.sub_header.2")}</strong>{" "}
                        {t("subscription.sub_header.3")}
                    </h2>
                    <ul>
                        {Object.values(
                            t("features.features_list", { returnObjects: true })
                        ).map((item) => (
                            <li key={item.toString()}>
                                <TickIcon className={styles.tick} />
                                <p>{item}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <SubscriptionsCard />
            </div>
        </PageSection>
    );
}
