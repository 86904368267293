import React from "react";
import styles from "./Footer.module.css";
import PageSection from "../PageSection";
import { Link } from "react-router-dom";
import { socialImages } from "./assets/images/socials";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../NavBar/LanguageSwitcher";

function FooterColumn({ header, items }) {
    return (
        <div className={styles.footerColumn}>
            <h6>{header}</h6>
            <ul>
                {items.map((it) => (
                    <li key={it.name}>
                        <a href={it.path}>{it.name}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default function Footer() {
    const { t } = useTranslation();
    return (
        <footer>
            <PageSection className={styles.container}>
                <div className={styles.layout}>
                    <div>
                        <div
                            className={[styles.social, "centeredRow"].join(" ")}
                        >
                            {Object.values(socialImages).map((it) => (
                                <img
                                    key={it}
                                    src={it}
                                    alt={"social media item"}
                                />
                            ))}
                        </div>
                        <div className={styles.watermark}>
                            @{new Date().getFullYear()} {t("common.malak")}
                        </div>
                    </div>
                    <FooterColumn
                        header={t("common.malak").toUpperCase()}
                        items={[
                            {
                                name: t("common.apps.ios"),
                                path: "",
                            },
                            {
                                name: t("common.apps.android"),
                                path: "",
                            },
                            {
                                name: t("nav.routes.privacy_policy"),
                                path: "/privacy_policy",
                            },
                        ]}
                    />
                    <div
                        style={{
                            color: "white !important",
                        }}
                    >
                        <h6>{t("common.language")}</h6>
                        <LanguageSwitcher
                            extended
                            style={{ color: "#fff !important" }}
                        />
                    </div>
                </div>
            </PageSection>
        </footer>
    );
}
