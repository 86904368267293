import React from "react";
import styles from "./StoresBar.module.css";
import useStoresIcons from "../../assets/images/stores";

export default function StoresBar({ className }) {
    const Stores = useStoresIcons();
    return (
        <div className={["centeredRow", styles.container, className].join(" ")}>
            {<Stores.AppStore />}
            {<Stores.PlayStore />}
        </div>
    );
}
