import "./App.css";
import NavBar from "./features/NavBar";
import Footer from "./features/Footer";
import {Route, Routes} from "react-router-dom";
import useAnchor from "./hooks/useAnchor";
import PrivacyPolicy from "./features/PrivacyPolicy";
import {useEffect, useState} from "react";
import RootPage from "./features/RootPage";
import {useTranslation} from "react-i18next";

function App() {
    const anchor = useAnchor();
    const [isNavigating, setNavigating] = useState(false);
    const {t, i18n} = useTranslation();

    const routes = [
        {
            path: "/",
            element: <RootPage isNavigating={isNavigating}/>,
        },
        {
            name: t("nav.routes.home"),
            path: "/#Home",
        },
        {
            name: t("nav.routes.features"),
            path: "/#Features",
        },
        {
            name: t("nav.routes.pricing"),
            path: "/#Pricing",
        },
        {
            name: t("nav.routes.privacy_policy"),
            path: "/privacy_policy",
            element: <PrivacyPolicy/>,
        },
    ];

    useEffect(() => {
    }, []);

    return (
        <main dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            <NavBar
                routes={routes}
                current={anchor}
                isNavigating={isNavigating}
                setNavigating={setNavigating}
            />
            <Routes>
                {routes.map((route) =>
                    route.element ? (
                        <Route
                            exact
                            key={route.path}
                            path={route.path}
                            element={route.element}
                        />
                    ) : null
                )}
            </Routes>
            <Footer/>
        </main>
    );
}

export default App;
