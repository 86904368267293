import { useTranslation } from "react-i18next";

import { ReactComponent as AppStoreAr } from "./appstore_ar.svg";
import { ReactComponent as AppStore } from "./appstore.svg";
import { ReactComponent as PlayStoreAr } from "./playstore_ar.svg";
import { ReactComponent as PlayStore } from "./playstore.svg";

function useStoresIcons() {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    return {
        AppStore: lang === "ar" ? AppStoreAr : AppStore,
        PlayStore: lang === "ar" ? PlayStoreAr : PlayStore,
    };
}

export default useStoresIcons;
