import React, { useRef } from "react";
import styles from "./Features.module.css";

export default function FeatureCard({ text, image }) {
    const ref = useRef();

    return (
        <div className={[styles.card].join(" ")} ref={ref}>
            <div style={{ backgroundImage: `url(${image})` }} />
            <p>{text}</p>
        </div>
    );
}
