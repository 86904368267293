import React, { useEffect, useState } from "react";
import styles from "./NavBar.module.css";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import NavList from "./NavList";
import Burger from "./Burger";
import useScrollPosition from "../../hooks/useScrollPosition";
import LanguageSwitcher from "./LanguageSwitcher";

export default function NavBar({ routes, current }) {
    const [isOpen, setIsOpen] = useState(false);
    const scrollPosition = useScrollPosition();

    useEffect(() => setIsOpen(false), [current]);
    return (
        <nav
            className={[
                styles.container,
                scrollPosition > 5 ? styles.scroll : "",
                isOpen ? styles.open : "",
                "unselectable",
            ].join(" ")}
        >
            <div
                className={["content", "centeredRow", styles.content].join(" ")}
            >
                <Logo className={styles.logo} />
                <NavList routes={routes} current={current} />
                <LanguageSwitcher className={styles.navLanguageSwitcher} />
                <Burger
                    className={styles.menuOpen}
                    isOpen={isOpen}
                    onClick={() => setIsOpen(!isOpen)}
                />
            </div>
        </nav>
    );
}
